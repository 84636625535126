"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const contact = {
    title: 'Nous contacter',
    description: "Participez à notre communauté pour fournir des informations, demander de l'aide et partager vos idées avec d'autres développeurs.",
    discord: {
        title: 'Serveur Discord',
        description: "Rejoignez notre serveur public pour discuter avec d'autres développeurs",
        button: 'Rejoindre',
    },
    github: {
        title: 'GitHub',
        description: 'Créer un ticket GitHub',
        button: 'Ouvrir',
    },
    email: {
        title: 'Envoyer un email',
        description: "Envoyez nous un email pour plus d'information et d'aide",
        button: 'Envoyer',
    },
};
exports.default = contact;
