"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const get_started = {
    progress: '开始上手: {{completed}}/{{total}}',
    progress_dropdown_title: '一些快速上手的操作',
    title: '还不知道如何使用 Logto?',
    subtitle_part1: '下列是一些可以快速上手的操作，通过这些，你可以更好地感受 Logto 的价值',
    subtitle_part2: '我已经完成了这些设置。 ',
    hide_this: '隐藏引导',
    confirm_message: '你确认要隐藏该页面吗? 本操作将无法恢复。',
    card1_title: '看看 Demo',
    card1_subtitle: '来体验 Logto 登录吧',
    card2_title: '创建你的第一款应用',
    card2_subtitle: '创建一个原生、单页或传统应用，并通过 Logto 进行身份验证',
    card3_title: '自定义你的登录体验',
    card3_subtitle: '自定义符合品牌形象的登录界面，并实时预览真实效果',
    card4_title: '配置短信和邮件连接器',
    card4_subtitle: '通过手机号或邮箱无密码登录以提供一个安全无缝的用户体验',
    card5_title: '添加社交连接器',
    card5_subtitle: '让你的用户通过社交帐号一键登录',
    card6_title: '更多阅读',
    card6_subtitle: '查看我们一步一步基于场景的文档，没有复杂的概念，深入浅出',
};
exports.default = get_started;
