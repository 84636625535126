"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logTypeGuard = exports.tokenTypeGuard = exports.TokenType = exports.baseLogPayloadGuard = exports.logResultGuard = exports.LogResult = void 0;
const zod_1 = require("zod");
var LogResult;
(function (LogResult) {
    LogResult["Success"] = "Success";
    LogResult["Error"] = "Error";
})(LogResult = exports.LogResult || (exports.LogResult = {}));
exports.logResultGuard = zod_1.z.nativeEnum(LogResult);
exports.baseLogPayloadGuard = zod_1.z.object({
    result: exports.logResultGuard.optional(),
    error: zod_1.z.record(zod_1.z.string(), zod_1.z.unknown()).optional(),
    ip: zod_1.z.string().optional(),
    userAgent: zod_1.z.string().optional(),
    applicationId: zod_1.z.string().optional(),
    sessionId: zod_1.z.string().optional(),
});
const arbitraryLogPayloadGuard = zod_1.z.record(zod_1.z.string(), zod_1.z.unknown());
const registerUsernamePasswordLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({ userId: zod_1.z.string().optional(), username: zod_1.z.string().optional() }));
const registerEmailSendPasscodeLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({ email: zod_1.z.string().optional(), connectorId: zod_1.z.string().optional() }));
const registerEmailLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    email: zod_1.z.string().optional(),
    code: zod_1.z.string().optional(),
    userId: zod_1.z.string().optional(),
}));
const registerSmsSendPasscodeLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    phone: zod_1.z.string().optional(),
    connectorId: zod_1.z.string().optional(),
}));
const registerSmsLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    phone: zod_1.z.string().optional(),
    code: zod_1.z.string().optional(),
    userId: zod_1.z.string().optional(),
}));
const registerSocialBindLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    connectorId: zod_1.z.string().optional(),
    userId: zod_1.z.string().optional(),
    userInfo: zod_1.z.unknown().optional(),
}));
const registerSocialLogPayloadGuard = registerSocialBindLogPayloadGuard.and(zod_1.z.object({
    code: zod_1.z.string().optional(),
    state: zod_1.z.string().optional(),
    redirectUri: zod_1.z.string().optional(),
    redirectTo: zod_1.z.string().optional(),
}));
const signInUsernamePasswordLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    userId: zod_1.z.string().optional(),
    username: zod_1.z.string().optional(),
}));
const signInEmailSendPasscodeLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    email: zod_1.z.string().optional(),
    connectorId: zod_1.z.string().optional(),
}));
const signInEmailLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    email: zod_1.z.string().optional(),
    code: zod_1.z.string().optional(),
    userId: zod_1.z.string().optional(),
}));
const signInSmsSendPasscodeLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    phone: zod_1.z.string().optional(),
    connectorId: zod_1.z.string().optional(),
}));
const signInSmsLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    phone: zod_1.z.string().optional(),
    code: zod_1.z.string().optional(),
    userId: zod_1.z.string().optional(),
}));
const signInSocialBindLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    connectorId: zod_1.z.string().optional(),
    userId: zod_1.z.string().optional(),
    userInfo: zod_1.z.unknown().optional(),
}));
const signInSocialLogPayloadGuard = signInSocialBindLogPayloadGuard.and(zod_1.z.object({
    code: zod_1.z.string().optional(),
    state: zod_1.z.string().optional(),
    redirectUri: zod_1.z.string().optional(),
    redirectTo: zod_1.z.string().optional(),
}));
const forgotPasswordSmsSendPasscodeLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    phone: zod_1.z.string().optional(),
    connectorId: zod_1.z.string().optional(),
}));
const forgotPasswordSmsLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    phone: zod_1.z.string().optional(),
    code: zod_1.z.string().optional(),
    userId: zod_1.z.string().optional(),
}));
const forgotPasswordEmailSendPasscodeLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    email: zod_1.z.string().optional(),
    connectorId: zod_1.z.string().optional(),
}));
const forgotPasswordEmailLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    email: zod_1.z.string().optional(),
    code: zod_1.z.string().optional(),
    userId: zod_1.z.string().optional(),
}));
const forgotPasswordResetLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    userId: zod_1.z.string().optional(),
}));
var TokenType;
(function (TokenType) {
    TokenType["AccessToken"] = "AccessToken";
    TokenType["RefreshToken"] = "RefreshToken";
    TokenType["IdToken"] = "IdToken";
})(TokenType = exports.TokenType || (exports.TokenType = {}));
exports.tokenTypeGuard = zod_1.z.nativeEnum(TokenType);
const exchangeTokenLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    userId: zod_1.z.string().optional(),
    params: zod_1.z.record(zod_1.z.string(), zod_1.z.unknown()).optional(),
    issued: exports.tokenTypeGuard.array().optional(),
    scope: zod_1.z.string().optional(),
}));
const revokeTokenLogPayloadGuard = arbitraryLogPayloadGuard.and(zod_1.z.object({
    userId: zod_1.z.string().optional(),
    params: zod_1.z.record(zod_1.z.string(), zod_1.z.unknown()).optional(),
    tokenType: exports.tokenTypeGuard.optional(),
    grantId: zod_1.z.string().optional(),
}));
const logPayloadsGuard = zod_1.z.object({
    RegisterUsernamePassword: registerUsernamePasswordLogPayloadGuard,
    RegisterEmailSendPasscode: registerEmailSendPasscodeLogPayloadGuard,
    RegisterEmail: registerEmailLogPayloadGuard,
    RegisterSmsSendPasscode: registerSmsSendPasscodeLogPayloadGuard,
    RegisterSms: registerSmsLogPayloadGuard,
    RegisterSocialBind: registerSocialBindLogPayloadGuard,
    RegisterSocial: registerSocialLogPayloadGuard,
    SignInUsernamePassword: signInUsernamePasswordLogPayloadGuard,
    SignInEmailSendPasscode: signInEmailSendPasscodeLogPayloadGuard,
    SignInEmail: signInEmailLogPayloadGuard,
    SignInSmsSendPasscode: signInSmsSendPasscodeLogPayloadGuard,
    SignInSms: signInSmsLogPayloadGuard,
    SignInSocialBind: signInSocialBindLogPayloadGuard,
    SignInSocial: signInSocialLogPayloadGuard,
    ForgotPasswordSmsSendPasscode: forgotPasswordSmsSendPasscodeLogPayloadGuard,
    ForgotPasswordSms: forgotPasswordSmsLogPayloadGuard,
    ForgotPasswordEmailSendPasscode: forgotPasswordEmailSendPasscodeLogPayloadGuard,
    ForgotPasswordEmail: forgotPasswordEmailLogPayloadGuard,
    ForgotPasswordReset: forgotPasswordResetLogPayloadGuard,
    CodeExchangeToken: exchangeTokenLogPayloadGuard,
    RefreshTokenExchangeToken: exchangeTokenLogPayloadGuard,
    RevokeToken: revokeTokenLogPayloadGuard,
});
exports.logTypeGuard = logPayloadsGuard.keyof();
