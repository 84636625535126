"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_resource_details = {
    back_to_api_resources: 'Back to API resources',
    token_expiration_time_in_seconds: 'Token expiration time (in seconds)',
    token_expiration_time_in_seconds_placeholder: 'Enter your token expiration time',
    delete_description: 'This action cannot be undone. It will permanently delete the API resource. Please enter the api resource name <span>{{name}}</span> to confirm.',
    enter_your_api_resource_name: 'Enter your API resource name',
    api_resource_deleted: 'The API Resource {{name}} has been successfully deleted',
};
exports.default = api_resource_details;
