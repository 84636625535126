@use '@/scss/underscore' as _;

.container {
  padding: _.unit(6);
  border-radius: 16px;
  // Force dark theme on the code editor
  background: #34353f;
  position: relative;

  .copy {
    display: flex;
    align-items: center;
    justify-content: right;
    position: absolute;
    top: 24px;
    right: 24px;
    opacity: 0%;
    transition: opacity 0.2s ease-in-out;
    z-index: 1;
    cursor: pointer;
  }

  &:hover {
    .copy {
      opacity: 100%;
    }
  }

  .editor {
    position: relative;

    textarea {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      border: none;
      background: none;
      font-size: 14px;
      line-height: 1.5;
      font-family: 'Roboto Mono', monospace;
      white-space: pre-wrap;
      word-break: break-all;
      position: absolute;
      inset: 0;
      resize: none;
      color: #fefefe;
      overflow: hidden;
      -webkit-text-fill-color: transparent;
      outline: none;
    }

    textarea,
    pre {
      display: flex;
      min-height: 20px;
    }
  }
}
