"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const settings = {
    title: '설정',
    description: '전체 설정을 관리해보세요.',
    tabs: {
        general: '일반',
    },
    custom_domain: '커스텀 도메인',
    language: '언어',
    appearance: '모습',
    appearance_system: '시스템과 동기화',
    appearance_light: '라이트 모드',
    appearance_dark: '다크 모드',
    saved: '저장되었어요!',
    change_password: '비밀번호 변경',
    change_password_description: '현재 계정의 비밀번호를 변경할 수 있어요.',
    change_modal_title: '계정 비밀번호 변경',
    change_modal_description: '새로 변경된 비밀번호로 로그인해야해요.',
    new_password: '새로운 비밀번호',
    new_password_placeholder: '새로운 비밀번호를 입력해주세요.',
    confirm_password: '비밀번호 확인',
    confirm_password_placeholder: '비밀번호를 다시 입력해주세요.',
    password_changed: '비밀번호 변경되었어요!',
};
exports.default = settings;
