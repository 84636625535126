"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const logs = {
    title: '审计日志',
    subtitle: '查看用户行为和事件',
    event: '事件',
    user: '用户',
    application: '应用',
    time: '时间',
    filter_by: '过滤',
};
exports.default = logs;
