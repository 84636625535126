@use '@/scss/underscore' as _;

.title {
  @include _.subhead-cap;
  color: var(--color-neutral-variant-60);
  margin-top: _.unit(12);

  &:first-child {
    margin-top: _.unit(6);
  }
}

.primaryTag {
  color: var(--color-text-secondary);
}

.method {
  margin-top: _.unit(3);
}

.primarySocial {
  margin-top: _.unit(2);
}

.darkModeTip {
  display: flex;
  align-items: baseline;
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
  margin-top: _.unit(1);
}

.manageLanguageButton {
  margin-top: _.unit(1);
}

.defaultLanguageDescription {
  padding-top: _.unit(2);
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
}
