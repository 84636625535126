"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultRole = void 0;
const types_1 = require("../types");
/**
 * Default Admin Role for Admin Console.
 */
exports.defaultRole = {
    name: types_1.UserRole.Admin,
    description: 'Admin role for Logto.',
};
