"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultSetting = exports.defaultSettingId = void 0;
const foundations_1 = require("../foundations");
exports.defaultSettingId = 'default';
const createDefaultSetting = () => Object.freeze({
    id: exports.defaultSettingId,
    adminConsole: {
        language: 'en',
        appearanceMode: foundations_1.AppearanceMode.SyncWithSystem,
        demoChecked: false,
        applicationCreated: false,
        signInExperienceCustomized: false,
        passwordlessConfigured: false,
        socialSignInConfigured: false,
        furtherReadingsChecked: false,
    },
});
exports.createDefaultSetting = createDefaultSetting;
