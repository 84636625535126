"use strict";
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
Object.defineProperty(exports, "__esModule", { value: true });
exports.Resources = void 0;
const zod_1 = require("zod");
const createGuard = zod_1.z.object({
    id: zod_1.z.string().max(21),
    name: zod_1.z.string(),
    indicator: zod_1.z.string(),
    accessTokenTtl: zod_1.z.number().optional(),
});
const guard = zod_1.z.object({
    id: zod_1.z.string().max(21),
    name: zod_1.z.string(),
    indicator: zod_1.z.string(),
    accessTokenTtl: zod_1.z.number(),
});
exports.Resources = Object.freeze({
    table: 'resources',
    tableSingular: 'resource',
    fields: {
        id: 'id',
        name: 'name',
        indicator: 'indicator',
        accessTokenTtl: 'access_token_ttl',
    },
    fieldKeys: ['id', 'name', 'indicator', 'accessTokenTtl'],
    createGuard,
    guard,
});
