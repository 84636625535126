@use '@/scss/underscore' as _;

.tabs {
  display: flex;
  align-items: center;

  .tab {
    display: flex;
    align-items: center;
    font: var(--font-subhead-2);
    color: var(--color-text);
    text-decoration: none;
    padding: 6px 12px;
    border-radius: 100px;
    background: var(--color-layer-1);
    border: 1px solid var(--color-surface-5);

    &:not(:last-child) {
      margin-right: _.unit(4);
    }

    .icon {
      width: 16px;
      height: 16px;
      object-fit: cover;
      margin-right: _.unit(1);
      color: var(--color-text-link);
    }

    &.active {
      background: var(--color-inverse-primary);
      color: var(--color-white);
      border-color: var(--color-inverse-primary);

      .icon {
        color: var(--color-button-icon);
      }
    }
  }
}
