"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tabs = {
    get_started: 'Başla',
    dashboard: 'Gösterge Paneli',
    applications: 'Uygulamalar',
    api_resources: 'API Kaynakları',
    sign_in_experience: 'Oturum Açma Deneyimi',
    connectors: 'Connectors',
    users: 'Kullanıcı Yönetimi',
    audit_logs: 'Denetim Kayıtları',
    docs: 'Dökümanlar',
    contact_us: 'Bize Ulaşın',
    settings: 'Ayarlar',
};
exports.default = tabs;
