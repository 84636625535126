"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const application_details = {
    back_to_applications: 'Retour aux applications',
    check_guide: 'Aller voir le guide',
    advanced_settings: 'Paramètres avancés',
    application_name: "Nom de l'application",
    application_name_placeholder: 'Mon App',
    description: 'Description',
    description_placeholder: 'Entrez la description de votre application',
    authorization_endpoint: 'Authorization endpoint',
    authorization_endpoint_tip: "Le point de terminaison pour effectuer l'authentification et l'autorisation. Il est utilisé pour l'authentification OpenID Connect.",
    application_id: 'App ID',
    application_secret: 'App Secret',
    redirect_uri: 'Redirect URI',
    redirect_uris: 'Redirect URIs',
    redirect_uri_placeholder: 'https://votre.site.com/app',
    redirect_uri_placeholder_native: 'io.logto://callback',
    redirect_uri_tip: "L'URI de redirection après la connexion d'un utilisateur (qu'elle soit réussie ou non). Voir OpenID Connect AuthRequest pour plus d'informations.",
    post_sign_out_redirect_uri: 'URI de redirection post-signature',
    post_sign_out_redirect_uris: 'URI de redirection après la signature',
    post_sign_out_redirect_uri_placeholder: 'https://votre.site.com/home',
    post_sign_out_redirect_uri_tip: "L'URI de redirection après la déconnexion de l'utilisateur (facultatif). Cela peut n'avoir aucun effet pratique dans certains types d'applications.",
    cors_allowed_origins: 'Origines CORS autorisées',
    cors_allowed_origins_placeholder: 'https://votre.site.com',
    cors_allowed_origins_tip: "Par défaut, toutes les origines des URI de redirection seront autorisées. En général, aucune action n'est requise pour ce champ.",
    add_another: 'Ajouter un autre',
    id_token_expiration: "Expiration du jeton d'identification",
    refresh_token_expiration: "Rafraîchir l'expiration du jeton",
    token_endpoint: 'Token Endpoint',
    user_info_endpoint: 'Userinfo Endpoint',
    enable_admin_access: 'Enable admin access',
    enable_admin_access_label: 'Enable or disable the access to Management API. Once enabled, you can use access tokens to call Management API on behalf on this application.',
    delete_description: "Cette action ne peut être annulée. Elle supprimera définitivement l'application. Veuillez entrer le nom de l'application <span>{{nom}}</span> pour confirmer.",
    enter_your_application_name: "Saisissez votre nom d'application",
    application_deleted: "L'application {{name}} a été supprimée avec succès.",
    redirect_uri_required: 'Vous devez entrer au moins un URI de redirection.',
};
exports.default = application_details;
