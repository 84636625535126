"use strict";
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogtoConfigs = void 0;
const zod_1 = require("zod");
const foundations_1 = require("../foundations");
const createGuard = zod_1.z.object({
    key: zod_1.z.string().max(256),
    value: foundations_1.arbitraryObjectGuard.optional(),
});
const guard = zod_1.z.object({
    key: zod_1.z.string().max(256),
    value: foundations_1.arbitraryObjectGuard,
});
exports.LogtoConfigs = Object.freeze({
    table: '_logto_configs',
    tableSingular: '_logto_config',
    fields: {
        key: 'key',
        value: 'value',
    },
    fieldKeys: ['key', 'value'],
    createGuard,
    guard,
});
