"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const demo_app = {
    notification: 'Utilisez votre compte administrateur par défaut ou créez un nouveau compte pour vous connecter à la démo.',
    title: 'Vous avez réussi à vous connecter à la démo !',
    subtitle: 'Voici vos informations de connexion :',
    username: "Nom d'utilisateur : ",
    user_id: "ID de l'utilisateur :",
    sign_out: 'Déconnexion de la démo',
    continue_explore: 'Ou continuer à explorer',
    customize_sign_in_experience: "Personnaliser l'expérience de connexion",
    enable_passwordless: 'Activer la fonction sans mot de passe',
    add_social_connector: 'Ajouter un connecteur social',
};
exports.default = demo_app;
