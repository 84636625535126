"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const connectors = {
    title: 'Conectores',
    subtitle: 'Configure conectores para habilitar a experiência de login social e sem senha',
    create: 'Adicionar conector social',
    tab_email_sms: 'Conectores de Email e SMS',
    tab_social: 'Conectores sociais',
    connector_name: 'Nome do conector',
    connector_type: 'Tipo',
    connector_status: 'Experiência de login',
    connector_status_in_use: 'Em uso',
    connector_status_not_in_use: 'Fora de uso',
    social_connector_eg: 'Ex., Google, Facebook, Github',
    save_and_done: 'Guardar',
    type: {
        email: 'Email',
        sms: 'SMS',
        social: 'Social',
    },
    setup_title: {
        email: 'Configurar o conector de email',
        sms: 'Configurar o conector de SMS',
        social: 'Adicionar conector social',
    },
    guide: {
        subtitle: 'Um guia passo a passo para configurar o conector',
    },
    platform: {
        universal: 'Universal',
        web: 'Web',
        native: 'Nativo',
    },
    add_multi_platform: ' suporta várias plataformas, selecione uma plataforma para continuar',
    drawer_title: 'Guia do conector',
    drawer_subtitle: 'Siga as instruções para integrar o conector',
};
exports.default = connectors;
