@use '@/scss/underscore' as _;

.card {
  @include _.flex-column;
}

.headline {
  display: flex;
  justify-content: space-between;
}

.tabs {
  margin-top: _.unit(4);
}

.table {
  margin-top: _.unit(4);
  flex: 1;
}

.connectorName {
  width: 360px;
}

.empty {
  text-align: center;
  font-size: var(--font-body-medium);
}

.emptyLine {
  padding: _.unit(2) 0;
}
