"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const logs = {
    title: 'Audit 기록',
    subtitle: '관리자나 사용자의 인증 기록을 확인해보세요.',
    event: '활동',
    user: '사용자',
    application: '어플리케이션',
    time: '시간',
    filter_by: '필터',
};
exports.default = logs;
