"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const users = {
    title: 'User Management',
    subtitle: 'Manage user identities including creating users, editing user information, viewing user logs, password resets and deleting users',
    create: 'Add User',
    user_name: 'User',
    application_name: 'From application',
    latest_sign_in: 'Latest sign in',
    create_form_username: 'Username',
    create_form_password: 'Password',
    create_form_name: 'Full name',
    unnamed: 'Unnamed',
};
exports.default = users;
