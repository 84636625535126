"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const log_details = {
    back_to_logs: 'Voltar aos registos de auditoria',
    back_to_user: 'De volta a {{name}}',
    success: 'Sucesso',
    failed: 'Falha',
    event_type: 'Tipo de evento',
    application: 'Aplicação',
    ip_address: 'Endereço IP',
    user: 'Utilizador',
    log_id: 'ID do registo',
    time: 'Hora',
    user_agent: 'User agent',
    tab_details: 'Detalhes',
    raw_data: 'Dados não tratados',
};
exports.default = log_details;
