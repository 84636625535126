"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const connectors = {
    title: 'Connectors',
    subtitle: 'Set up connectors to enable passwordless and social sign in experience',
    create: 'Add Social Connector',
    tab_email_sms: 'Email and SMS connectors',
    tab_social: 'Social connectors',
    connector_name: 'Connector name',
    connector_type: 'Type',
    connector_status: 'Sign in Experience',
    connector_status_in_use: 'In use',
    connector_status_not_in_use: 'Not in use',
    social_connector_eg: 'E.g., Google, Facebook, Github',
    save_and_done: 'Save and Done',
    type: {
        email: 'Email connector',
        sms: 'SMS connector',
        social: 'Social connector',
    },
    setup_title: {
        email: 'Set up email connector',
        sms: 'Set up SMS connector',
        social: 'Add Social Connector',
    },
    guide: {
        subtitle: 'A step by step guide to configure your connector',
    },
    platform: {
        universal: 'Universal',
        web: 'Web',
        native: 'Native',
    },
    add_multi_platform: ' supports multiple platform, select a platform to continue',
    drawer_title: 'Connector Guide',
    drawer_subtitle: 'Follow the instructions to integrate your connector',
};
exports.default = connectors;
