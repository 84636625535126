"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const connector_details = {
    back_to_connectors: '연동으로 돌아가기',
    check_readme: 'README 확인',
    save_error_empty_config: '설정을 입력해주세요.',
    send: '보내기',
    send_error_invalid_format: '유효하지 않은 입력',
    edit_config_label: '여기에 JSON을 입력해주세요.',
    test_email_sender: '이메일 연동 테스트',
    test_sms_sender: 'SMS 연동 테스트',
    test_email_placeholder: '테스트 이메일 주소를 입력해주세요.',
    test_sms_placeholder: '테스트 휴대전화번호를 입력해주세요.',
    test_message_sent: '테스트 메세지 전송완료!',
    test_sender_description: 'JSON 설정이 정확하다면, 메세지를 받을거에요.',
    options_change_email: '이메일 연동 수정',
    options_change_sms: 'SMS 연동 수정',
    connector_deleted: '연동이 설공적으로 제거되었어요.',
    type_email: '이메일 연동',
    type_sms: 'SMS 연동',
    type_social: '소셜 연동',
};
exports.default = connector_details;
