"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_resources = {
    title: 'API Kaynakları',
    subtitle: 'Yetkili uygulamalarınızdan kullanabileceğiniz APIleri tanımlayınız',
    create: 'API Kaynağı oluştur',
    api_name: 'API Adı',
    api_name_placeholder: 'API adını giriniz',
    api_identifier: 'API belirteci',
    api_identifier_tip: 'Api kaynağına özgün belirteç. Mutlak URI olmalı ve parça bileşeni (#) içermemeli. OAuth 2.0deki kaynak parametresine eşittir.',
    api_resource_created: '{{name}} API kaynağı başarıyla oluşturuldu',
    api_identifier_placeholder: 'https://your-api-identifier/',
};
exports.default = api_resources;
