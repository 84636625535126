"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRole = exports.userInfoSelectFields = void 0;
exports.userInfoSelectFields = Object.freeze([
    'id',
    'username',
    'primaryEmail',
    'primaryPhone',
    'name',
    'avatar',
    'roleNames',
    'customData',
    'identities',
    'lastSignInAt',
    'createdAt',
    'applicationId',
]);
var UserRole;
(function (UserRole) {
    UserRole["Admin"] = "admin";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
