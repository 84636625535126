"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dashboard = {
    title: 'Gösterge Paneli',
    description: 'Uygulamanızın performansı hakkında genel bir bakış elde edin',
    total_users: 'Tüm Kullanıcılar',
    total_users_tip: 'Tüm kullanıcılar',
    new_users_today: 'Bugün katılan kullanıcılar',
    new_users_today_tip: 'Uygulamamıza bugün katılan yeni kullanıcı sayısı',
    new_users_7_days: 'Son 7 günde katılan kullanıcılar',
    new_users_7_days_tip: 'Uygulamamıza son 7 günde katılan kullanıcı sayısı',
    daily_active_users: 'Günlük aktif kullanıcılar',
    daily_active_users_tip: 'Bugün uygulamalarınızda token değişiminde bulunan özgün kullanıcı sayısı',
    weekly_active_users: 'Haftalık aktif kullanıcılar',
    weekly_active_users_tip: 'Son 7 günde uygulamalarınızda token değişiminde bulunan özgün kullanıcı sayısı',
    monthly_active_users: 'Aylık aktif kullanıcılar',
    monthly_active_users_tip: 'Son 30 günde uygulamalarınızda token değişiminde bulunan özgün kullanıcı sayısı',
};
exports.default = dashboard;
