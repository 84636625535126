"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const demo_app = {
    notification: '管理控制台的用户名和密码是 demo app 的默认登录方式。点击下方创建账号或用现有账号登录。',
    title: '恭喜！你已成功登录到示例应用！',
    subtitle: '以下是本次登录的用户信息：',
    username: '用户名：',
    user_id: '用户 ID：',
    sign_out: '退出登录示例应用',
    continue_explore: '或继续探索',
    customize_sign_in_experience: '自定义登录体验',
    enable_passwordless: '启用无密码登录',
    add_social_connector: '添加社交连接器',
};
exports.default = demo_app;
