"use strict";
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomPhrases = void 0;
const zod_1 = require("zod");
const foundations_1 = require("../foundations");
const createGuard = zod_1.z.object({
    languageTag: zod_1.z.string().max(16),
    translation: foundations_1.translationGuard,
});
const guard = zod_1.z.object({
    languageTag: zod_1.z.string().max(16),
    translation: foundations_1.translationGuard,
});
exports.CustomPhrases = Object.freeze({
    table: 'custom_phrases',
    tableSingular: 'custom_phrase',
    fields: {
        languageTag: 'language_tag',
        translation: 'translation',
    },
    fieldKeys: ['languageTag', 'translation'],
    createGuard,
    guard,
});
