"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tabs = {
    get_started: 'Commencez',
    dashboard: 'Tableau de bord',
    applications: 'Applications',
    api_resources: 'Ressources API',
    sign_in_experience: 'Expérience de connexion',
    connectors: 'Connecteurs',
    users: 'Gestion des utilisateurs',
    audit_logs: "Journaux d'audit",
    docs: 'Documentation',
    contact_us: 'Contactez nous',
    settings: 'Paramètres',
};
exports.default = tabs;
