"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dashboard = {
    title: '仪表盘',
    description: '查看所有应用的数据概况',
    total_users: '总用户',
    total_users_tip: '总用户',
    new_users_today: '今日新增',
    new_users_today_tip: '今日注册到你应用上的新用户数',
    new_users_7_days: '7 日新增',
    new_users_7_days_tip: '最近 7 日注册到你应用上的新用户数',
    daily_active_users: '日活用户',
    daily_active_users_tip: '今日在你的应用上交换过 token 的独立用户数',
    weekly_active_users: '周活用户',
    weekly_active_users_tip: '最近 7 日在你的应用上交换过 token 的独立用户数',
    monthly_active_users: '月活用户',
    monthly_active_users_tip: '最近 30 日在你的应用上交换过 token 的独立用户数',
};
exports.default = dashboard;
