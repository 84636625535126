@use '@/scss/underscore' as _;

.userName {
  font: var(--body-medium);
  color: var(--color-text);

  .userId {
    font: var(--body-small);
    color: var(--color-text-secondary);
    margin-left: _.unit(1);
  }

  .link {
    text-decoration: none;
    color: var(--color-text-link);
  }
}
