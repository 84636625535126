"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logtoConfigGuards = exports.logtoConfigKeys = exports.logtoOidcConfigGuard = exports.LogtoOidcConfigKey = exports.alterationStateGuard = exports.AlterationStateKey = void 0;
const zod_1 = require("zod");
// Alteration state
var AlterationStateKey;
(function (AlterationStateKey) {
    AlterationStateKey["AlterationState"] = "alterationState";
})(AlterationStateKey = exports.AlterationStateKey || (exports.AlterationStateKey = {}));
exports.alterationStateGuard = Object.freeze({
    [AlterationStateKey.AlterationState]: zod_1.z.object({
        timestamp: zod_1.z.number(),
        updatedAt: zod_1.z.string().optional(),
    }),
});
// Logto OIDC config
var LogtoOidcConfigKey;
(function (LogtoOidcConfigKey) {
    LogtoOidcConfigKey["PrivateKeys"] = "oidc.privateKeys";
    LogtoOidcConfigKey["CookieKeys"] = "oidc.cookieKeys";
    LogtoOidcConfigKey["RefreshTokenReuseInterval"] = "oidc.refreshTokenReuseInterval";
})(LogtoOidcConfigKey = exports.LogtoOidcConfigKey || (exports.LogtoOidcConfigKey = {}));
exports.logtoOidcConfigGuard = Object.freeze({
    [LogtoOidcConfigKey.PrivateKeys]: zod_1.z.string().array(),
    [LogtoOidcConfigKey.CookieKeys]: zod_1.z.string().array(),
    /**
     * This interval helps to avoid concurrency issues when exchanging the rotating refresh token multiple times within a given timeframe.
     * During the leeway window (in seconds), the consumed refresh token will be considered as valid.
     * This is useful for distributed apps and serverless apps like Next.js, in which there is no shared memory.
     */
    [LogtoOidcConfigKey.RefreshTokenReuseInterval]: zod_1.z.number().gte(3),
});
exports.logtoConfigKeys = Object.freeze([
    ...Object.values(AlterationStateKey),
    ...Object.values(LogtoOidcConfigKey),
]);
exports.logtoConfigGuards = Object.freeze({
    ...exports.alterationStateGuard,
    ...exports.logtoOidcConfigGuard,
});
