"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_resources = {
    title: 'API 리소스',
    subtitle: '인증된 어플리케이션에서 사용될 API를 정의해주세요.',
    create: 'API 리소스 생성',
    api_name: 'API 이름',
    api_name_placeholder: 'API 이름 입력',
    api_identifier: 'API 식별자',
    api_identifier_tip: 'API 리소스에 대한 유일한 식별자예요. 반드시, 절대적인 URI 이여야 하며, 프래그먼트 (#) 요소가 없어야해요. OAuth 2.0의 리소스 파라미터와 동일해요.',
    api_resource_created: '{{name}} API 리소스가 성공적으로 생성되었어요.',
    api_identifier_placeholder: 'https://your-api-identifier/',
};
exports.default = api_resources;
