"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const logs = {
    title: 'Audit Logs',
    subtitle: 'View log data of authentication events made by your admin and users',
    event: 'Event',
    user: 'User',
    application: 'Application',
    time: 'Time',
    filter_by: 'Filter by',
};
exports.default = logs;
