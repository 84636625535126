"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const connector_details = {
    back_to_connectors: 'Voltar para Conectores',
    check_readme: 'Verifique o README',
    save_error_empty_config: 'Por favor, insira a configuração',
    send: 'Enviar',
    send_error_invalid_format: 'Entrada inválida',
    edit_config_label: 'Introduza o JSON aqui',
    test_email_sender: 'Teste o conector de email',
    test_sms_sender: 'Teste o conector de SMS',
    test_email_placeholder: 'Insira um endereço de email para o teste',
    test_sms_placeholder: 'Insira um número de telefone para o teste',
    test_message_sent: 'Mensagem de teste enviada!',
    test_sender_description: 'Receberá uma mensagem se o JSON estiver configurado corretamente',
    options_change_email: 'Alterar conector de email',
    options_change_sms: 'Alterar conector de SMS',
    connector_deleted: 'O conector foi removido com sucesso',
    type_email: 'Conector de Email',
    type_sms: 'Conector de SMS',
    type_social: 'Conector Social',
};
exports.default = connector_details;
