"use strict";
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
Object.defineProperty(exports, "__esModule", { value: true });
exports.OidcModelInstances = void 0;
const zod_1 = require("zod");
const foundations_1 = require("../foundations");
const createGuard = zod_1.z.object({
    modelName: zod_1.z.string().max(64),
    id: zod_1.z.string().max(128),
    payload: foundations_1.oidcModelInstancePayloadGuard,
    expiresAt: zod_1.z.number(),
    consumedAt: zod_1.z.number().nullable().optional(),
});
const guard = zod_1.z.object({
    modelName: zod_1.z.string().max(64),
    id: zod_1.z.string().max(128),
    payload: foundations_1.oidcModelInstancePayloadGuard,
    expiresAt: zod_1.z.number(),
    consumedAt: zod_1.z.number().nullable(),
});
exports.OidcModelInstances = Object.freeze({
    table: 'oidc_model_instances',
    tableSingular: 'oidc_model_instance',
    fields: {
        modelName: 'model_name',
        id: 'id',
        payload: 'payload',
        expiresAt: 'expires_at',
        consumedAt: 'consumed_at',
    },
    fieldKeys: ['modelName', 'id', 'payload', 'expiresAt', 'consumedAt'],
    createGuard,
    guard,
});
