"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_resources = {
    title: 'Recursos API',
    subtitle: 'Defina APIs que pode consumir nos aplicações autorizadas',
    create: 'Criar recurso API',
    api_name: 'Nome da API',
    api_name_placeholder: 'Introduza o nome da sua API',
    api_identifier: 'identificador da API',
    api_identifier_tip: 'O identificador exclusivo para o recurso API. Deve ser um URI absoluto e não tem componente de fragmento (#). Igual ao resource parameter no OAuth 2.0.',
    api_resource_created: 'O recurso API {{name}} foi criado com sucesso',
    api_identifier_placeholder: 'https://your-api-identifier/',
};
exports.default = api_resources;
