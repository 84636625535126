"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_resource_details = {
    back_to_api_resources: 'Voltar aos recursos API',
    token_expiration_time_in_seconds: 'Tempo de expiração do token (em segundos)',
    token_expiration_time_in_seconds_placeholder: 'Insira o tempo de expiração do token',
    delete_description: 'Esta ação não pode ser desfeita. Isso ira eliminar permanentemente o recurso API. Insira o nome do recurso <span>{{name}}</span> para confirmar.',
    enter_your_api_resource_name: 'Digite o nome do recurso API',
    api_resource_deleted: 'O recurso API {{name}} foi eliminado com sucesso',
};
exports.default = api_resource_details;
