"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const get_started = {
    progress: 'Başlangıç Kılavuzu: {{completed}}/{{total}}',
    progress_dropdown_title: 'Yapabileceğiniz bazı şeyler...',
    title: 'Logtoyla nasıl başlamak istersiniz?',
    subtitle_part1: 'Logto değerini hızlı bir şekilde elde etmek için yapabileceğiniz birkaç şey',
    subtitle_part2: 'Bu kurulumla işim bitti. ',
    hide_this: 'Bunu gizle',
    confirm_message: 'Bu sayfayı gizlemek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
    card1_title: 'Demoya göz atın',
    card1_subtitle: 'Nasıl çalıştığını görmek için şimdi Logto oturum açma deneyimini deneyiniz',
    card2_title: 'İlk uygulamayı oluşturun ve entegre edin',
    card2_subtitle: 'Kimlik doğrulaması için Logtoyu kullanmak üzere mobil, tek sayfa veya geleneksel bir uygulama ayarlayın',
    card3_title: 'Oturum açma deneyimini özelleştirin',
    card3_subtitle: 'Oturum açma kullanıcı arayüzünü markanıza uyacak şekilde özelleştiriniz ve gerçek zamanlı olarak görüntüleyiniz',
    card4_title: 'SMS ve e-posta connectorunu ayarlayınız',
    card4_subtitle: 'Güvenli ve sorunsuz bir müşteri deneyimi sağlamak için telefon numarası veya e-posta ile şifresiz oturum açmayı deneyiniz',
    card5_title: 'Bir social connector ekle',
    card5_subtitle: 'Müşterilerinizin tek tıklamayla sosyal kimliklerle uygulamanızda oturum açmasına izin verin',
    card6_title: 'Daha fazla okuma',
    card6_subtitle: 'Sıkıcı kavramlar olmadan adım adım senaryo tabanlı belgelerimize göz atın',
};
exports.default = get_started;
