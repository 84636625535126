"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const demo_app = {
    notification: 'Use a sua conta de administrador padrão ou crie uma nova conta para entrar na app de demonstração.',
    title: 'Entrou com sucesso na app de demonstração!',
    subtitle: 'Aqui estão as suas informações de login:',
    username: 'Utilizador: ',
    user_id: 'ID de utilizador: ',
    sign_out: 'Sair da app de demonstração',
    continue_explore: 'Ou continue a explorar',
    customize_sign_in_experience: 'Personalizar a experiência de login',
    enable_passwordless: 'Ativar login sem passsword (passwordless)',
    add_social_connector: 'Adicionar conector socials',
};
exports.default = demo_app;
