"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const settings = {
    title: 'Definições',
    description: 'Gerenciar as configurações globais',
    tabs: {
        general: 'Geral',
    },
    custom_domain: 'Domínio personalizado',
    language: 'Linguagem',
    appearance: 'Aparência',
    appearance_system: 'Sincronizar com o sistema',
    appearance_light: 'Claro',
    appearance_dark: 'Escuro',
    saved: 'Guardado!',
    change_password: 'Mudar password',
    change_password_description: 'Pode alterar a senha desta conta. Usará o nome de utilizador atual com a nova password para fazer login na consola.',
    change_modal_title: 'Alterar password da conta',
    change_modal_description: 'Usará o nome de utilizador atual com a nova password para fazer login na consola.',
    new_password: 'Nova Password',
    new_password_placeholder: 'Introduza a password',
    confirm_password: 'Confirme a password',
    confirm_password_placeholder: 'Confirme a password',
    password_changed: 'Password alterada!',
};
exports.default = settings;
