"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adminConsoleSignInExperience = exports.defaultSignInExperience = void 0;
const core_kit_1 = require("@logto/core-kit");
const db_entries_1 = require("../db-entries");
const foundations_1 = require("../foundations");
const defaultPrimaryColor = '#6139F6';
exports.defaultSignInExperience = {
    id: 'default',
    color: {
        primaryColor: defaultPrimaryColor,
        isDarkModeEnabled: false,
        darkPrimaryColor: (0, core_kit_1.generateDarkColor)(defaultPrimaryColor),
    },
    branding: {
        style: foundations_1.BrandingStyle.Logo,
        logoUrl: 'https://logto.io/logo.svg',
        darkLogoUrl: 'https://logto.io/logo-dark.svg',
    },
    languageInfo: {
        autoDetect: true,
        fallbackLanguage: 'en',
    },
    termsOfUse: {
        enabled: false,
    },
    signInMethods: {
        username: foundations_1.SignInMethodState.Primary,
        email: foundations_1.SignInMethodState.Disabled,
        sms: foundations_1.SignInMethodState.Disabled,
        social: foundations_1.SignInMethodState.Disabled,
    },
    socialSignInConnectorTargets: [],
    signInMode: db_entries_1.SignInMode.SignInAndRegister,
};
exports.adminConsoleSignInExperience = {
    ...exports.defaultSignInExperience,
    color: {
        ...exports.defaultSignInExperience.color,
        isDarkModeEnabled: true,
    },
    branding: {
        style: foundations_1.BrandingStyle.Logo_Slogan,
        logoUrl: 'https://logto.io/logo.svg',
        darkLogoUrl: 'https://logto.io/logo-dark.svg',
    },
};
