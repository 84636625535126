import { SVGProps } from 'react';

const Web = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 5C9.80222 5 9.60888 5.05865 9.44443 5.16853C9.27998 5.27841 9.15181 5.43459 9.07612 5.61732C9.00043 5.80004 8.98063 6.00111 9.01921 6.19509C9.0578 6.38907 9.15304 6.56725 9.29289 6.70711C9.43275 6.84696 9.61093 6.9422 9.80491 6.98079C9.99889 7.01937 10.2 6.99957 10.3827 6.92388C10.5654 6.84819 10.7216 6.72002 10.8315 6.55557C10.9414 6.39112 11 6.19778 11 6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5ZM6 5C5.80222 5 5.60888 5.05865 5.44443 5.16853C5.27998 5.27841 5.15181 5.43459 5.07612 5.61732C5.00043 5.80004 4.98063 6.00111 5.01921 6.19509C5.0578 6.38907 5.15304 6.56725 5.29289 6.70711C5.43275 6.84696 5.61093 6.9422 5.80491 6.98079C5.99889 7.01937 6.19996 6.99957 6.38268 6.92388C6.56541 6.84819 6.72159 6.72002 6.83147 6.55557C6.94135 6.39112 7 6.19778 7 6C7 5.73478 6.89464 5.48043 6.70711 5.29289C6.51957 5.10536 6.26522 5 6 5ZM14 5C13.8022 5 13.6089 5.05865 13.4444 5.16853C13.28 5.27841 13.1518 5.43459 13.0761 5.61732C13.0004 5.80004 12.9806 6.00111 13.0192 6.19509C13.0578 6.38907 13.153 6.56725 13.2929 6.70711C13.4327 6.84696 13.6109 6.9422 13.8049 6.98079C13.9989 7.01937 14.2 6.99957 14.3827 6.92388C14.5654 6.84819 14.7216 6.72002 14.8315 6.55557C14.9414 6.39112 15 6.19778 15 6C15 5.73478 14.8946 5.48043 14.7071 5.29289C14.5196 5.10536 14.2652 5 14 5ZM20 1H4C3.20435 1 2.44129 1.31607 1.87868 1.87868C1.31607 2.44129 1 3.20435 1 4V20C1 20.7956 1.31607 21.5587 1.87868 22.1213C2.44129 22.6839 3.20435 23 4 23H20C20.7956 23 21.5587 22.6839 22.1213 22.1213C22.6839 21.5587 23 20.7956 23 20V4C23 3.20435 22.6839 2.44129 22.1213 1.87868C21.5587 1.31607 20.7956 1 20 1ZM21 20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V11H21V20ZM21 9H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V9Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Web;
