@use '@logto/core-kit/scss/console-themes' as themes;

.light {
  @include themes.light;
}

.dark {
  @include themes.dark;
}

@media (prefers-color-scheme: light) {
  body {
    @include themes.light;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    @include themes.dark;
  }
}
