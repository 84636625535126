import { SVGProps } from 'react';

const Box = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.47 7.37005C20.47 7.37005 20.47 7.37005 20.47 7.29005L20.41 7.14005C20.3891 7.10822 20.3657 7.07812 20.34 7.05005C20.3133 7.00772 20.2832 6.96759 20.25 6.93005L20.16 6.86005L20 6.78005L12.5 2.15005C12.3411 2.05072 12.1574 1.99805 11.97 1.99805C11.7826 1.99805 11.599 2.05072 11.44 2.15005L4.00002 6.78005L3.91002 6.86005L3.82002 6.93005C3.78687 6.96759 3.75678 7.00772 3.73002 7.05005C3.70431 7.07812 3.68091 7.10822 3.66002 7.14005L3.60002 7.29005C3.60002 7.29005 3.60002 7.29005 3.60002 7.37005C3.59019 7.45644 3.59019 7.54366 3.60002 7.63005V16.3701C3.59968 16.54 3.64266 16.7072 3.72489 16.8559C3.80713 17.0047 3.92591 17.13 4.07002 17.22L11.57 21.85C11.6162 21.8786 11.6669 21.8989 11.72 21.91C11.72 21.91 11.77 21.91 11.8 21.91C11.9692 21.9637 12.1508 21.9637 12.32 21.91C12.32 21.91 12.37 21.91 12.4 21.91C12.4531 21.8989 12.5039 21.8786 12.55 21.85L20 17.22C20.1441 17.13 20.2629 17.0047 20.3452 16.8559C20.4274 16.7072 20.4704 16.54 20.47 16.3701V7.63005C20.4799 7.54366 20.4799 7.45644 20.47 7.37005ZM11 19.21L5.50002 15.8101V9.43005L11 12.82V19.21ZM12 11.09L6.40002 7.63005L12 4.18005L17.6 7.63005L12 11.09ZM18.5 15.8101L13 19.21V12.82L18.5 9.43005V15.8101Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Box;
