"use strict";
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
Object.defineProperty(exports, "__esModule", { value: true });
exports.Settings = void 0;
const zod_1 = require("zod");
const foundations_1 = require("../foundations");
const createGuard = zod_1.z.object({
    id: zod_1.z.string().max(21),
    adminConsole: foundations_1.adminConsoleConfigGuard,
});
const guard = zod_1.z.object({
    id: zod_1.z.string().max(21),
    adminConsole: foundations_1.adminConsoleConfigGuard,
});
exports.Settings = Object.freeze({
    table: 'settings',
    tableSingular: 'setting',
    fields: {
        id: 'id',
        adminConsole: 'admin_console',
    },
    fieldKeys: ['id', 'adminConsole'],
    createGuard,
    guard,
});
