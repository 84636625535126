"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const connectors = {
    title: '연동',
    subtitle: '비밀번호 없이 또는 소셜 로그인을 제공하여 보다 나은 경험을 위해 연동해주세요.',
    create: '소셜 연동 추가',
    tab_email_sms: '이메일/SMS 연동',
    tab_social: '소셜 연동',
    connector_name: '연동 이름',
    connector_type: '종류',
    connector_status: '로그인 경험',
    connector_status_in_use: '사용 중',
    connector_status_not_in_use: '사용 중이 아님',
    social_connector_eg: '예) Google, Facebook, Github',
    save_and_done: '저장 및 완료',
    type: {
        email: '이메일 연동',
        sms: 'SMS 연동',
        social: '소셜 연동',
    },
    setup_title: {
        email: '이메일 연동 설정',
        sms: 'SMS 연동 설정',
        social: '소셜 연동 추가 및 설정',
    },
    guide: {
        subtitle: '단계별 가이드를 따라, 연동해주세요.',
    },
    platform: {
        universal: 'Universal',
        web: 'Web',
        native: 'Native',
    },
    add_multi_platform: ' 다양한 플랫폼 지원, 플랫폼을 선택해주세요.',
    drawer_title: '연동 가이드',
    drawer_subtitle: '연동하기 위해 가이드를 따라주세요.',
};
exports.default = connectors;
