"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const users = {
    title: 'Gestion des utilisateurs',
    subtitle: "Gérer les identités des utilisateurs, y compris la création d'utilisateurs, la modification des informations sur les utilisateurs, la consultation des journaux des utilisateurs, la réinitialisation des mots de passe et la suppression des utilisateurs.",
    create: 'Ajouter un utilisateur',
    user_name: 'Utilisateur',
    application_name: "De l'application",
    latest_sign_in: 'Dernière connexion',
    create_form_username: "Nom d'utilisateur",
    create_form_password: 'Mot de passe',
    create_form_name: 'Nom complet',
    unnamed: 'Sans nom',
};
exports.default = users;
