"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const contact = {
    title: 'Contact Us',
    description: 'Join in our community to provide feedback, ask for help and share your thoughts with other developers',
    discord: {
        title: 'Discord channel',
        description: 'Join our public channel to chat with other developers',
        button: 'Join',
    },
    github: {
        title: 'GitHub',
        description: 'Create an issue and submit at GitHub',
        button: 'Open',
    },
    email: {
        title: 'Send email',
        description: 'Send us an email for further information and help',
        button: 'Send',
    },
};
exports.default = contact;
