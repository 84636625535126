"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const users = {
    title: 'Gestão de utilizadores',
    subtitle: 'Gerencie os utilizadores, incluindo a criação, edição de informações, visualização de logs, recuperações de password e exclusões',
    create: 'Adicionar usuário',
    user_name: 'Utilizador',
    application_name: 'Do app',
    latest_sign_in: 'Último login',
    create_form_username: 'Utilizador',
    create_form_password: 'Password',
    create_form_name: 'Nome completo',
    unnamed: 'Sem nome',
};
exports.default = users;
