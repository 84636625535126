"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const logs = {
    title: 'Registos de auditoria',
    subtitle: 'Visualize logs de autenticação feitos por administradores e utilizadores',
    event: 'Evento',
    user: 'Utilizador',
    application: 'Aplicação',
    time: 'Hora',
    filter_by: 'Filtrar pors',
};
exports.default = logs;
