const Previous = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.04908 9.40832L11.7657 4.69999C11.8432 4.62188 11.9354 4.55989 12.0369 4.51758C12.1385 4.47527 12.2474 4.45349 12.3574 4.45349C12.4674 4.45349 12.5763 4.47527 12.6779 4.51758C12.7794 4.55989 12.8716 4.62188 12.9491 4.69999C13.1043 4.85613 13.1914 5.06734 13.1914 5.28749C13.1914 5.50764 13.1043 5.71885 12.9491 5.87499L8.82408 10.0417L12.9491 14.1667C13.1043 14.3228 13.1914 14.534 13.1914 14.7542C13.1914 14.9743 13.1043 15.1855 12.9491 15.3417C12.8719 15.4204 12.7799 15.483 12.6783 15.526C12.5767 15.5689 12.4677 15.5912 12.3574 15.5917C12.2472 15.5912 12.1381 15.5689 12.0365 15.526C11.935 15.483 11.8429 15.4204 11.7657 15.3417L7.04908 10.6333C6.96449 10.5553 6.89698 10.4606 6.85081 10.3552C6.80464 10.2497 6.7808 10.1359 6.7808 10.0208C6.7808 9.90574 6.80464 9.7919 6.85081 9.68648C6.89698 9.58107 6.96449 9.48636 7.04908 9.40832Z"
        fill="#747778"
      />
    </svg>
  );
};

export default Previous;
