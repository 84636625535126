@use '@/scss/underscore' as _;

.title {
  font: var(--font-subhead-2);
  color: var(--color-text);
  padding-right: _.unit(6); /* For check mark */
}

.subtitle,
.description {
  margin-top: _.unit(3);
  flex: 2;
}

.subtitle {
  font: var(--font-body-medium);
  color: var(--color-text);
}

.description {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
}
