"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const logs = {
    title: "Journaux d'audit",
    subtitle: "Visualisez les données du journal des événements d'authentification effectués par votre administrateur et vos utilisateurs.",
    event: 'Événement',
    user: 'Utilisateur',
    application: 'Application',
    time: 'Temps',
    filter_by: 'Filtrer par',
};
exports.default = logs;
