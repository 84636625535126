"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const general = {
    placeholder: '플레이스홀더',
    skip: '건너뛰기',
    next: '다음',
    retry: '재시도',
    done: '완료',
    search: '검색',
    search_placeholder: '검색',
    clear_result: '결과 지우기',
    save: '저장',
    save_changes: '변경 내용 저장',
    saved: '저장됨!',
    loading: '로딩 중...',
    redirecting: '리다이렉팅 중...',
    add: '추가',
    added: '추가됨',
    cancel: '취소',
    confirm: '확인',
    check_out: '확인하기',
    create: '생성',
    set_up: '설정',
    customize: '커스터마이즈',
    enable: '활성화',
    reminder: '리마인더',
    delete: '삭제',
    more_options: '더 많은 설정',
    close: '닫기',
    copy: '복사',
    copying: '복사 중',
    copied: '복사됨',
    required: '필수',
    add_another: '+ 새로 추가',
    deletion_confirmation: '정말로 {{title}}을/를 삭제할까요?',
    settings_nav: '설정',
    unsaved_changes_warning: '수정된 내용이 있어요. 정말로 현재 페이지를 벗어날까요?',
    leave_page: '페이지 나가기',
    stay_on_page: '페이지 유지하기',
    type_to_search: 'Type to search', // UNTRANSLATED
};
exports.default = general;
