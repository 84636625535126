"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const users = {
    title: 'Kullanıcı Yönetimi',
    subtitle: 'Kullanıcı oluşturma, kullanıcı bilgilerini düzenleme, kullanıcı kayıtlarını görüntüleme, parola sıfırlama ve kullanıcıları silme dahil olmak üzere kullanıcı kimliklerini yönetin',
    create: 'Kullanıcı ekle',
    user_name: 'Kullanıcı',
    application_name: 'Uygulamadan',
    latest_sign_in: 'En son oturum açma',
    create_form_username: 'Kullanıcı Adı',
    create_form_password: 'Şifre',
    create_form_name: 'Ad Soyad',
    unnamed: 'İsimsiz',
};
exports.default = users;
