"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const settings = {
    title: 'Paramètres',
    description: 'Gérer les paramètres globaux',
    tabs: {
        general: 'Général',
    },
    custom_domain: 'Domaine personnalisé',
    language: 'Langue',
    appearance: 'Apparence',
    appearance_system: 'Synchronisation avec le système',
    appearance_light: 'Mode clair',
    appearance_dark: 'Mode sombre',
    saved: 'Sauvegardé !',
    change_password: 'Changer le mot de passe',
    change_password_description: "Vous pouvez changer le mot de passe de ce compte. Vous utiliserez le nom d'utilisateur actuel avec le nouveau mot de passe pour vous connecter à la console d'administration.",
    change_modal_title: 'Changer le mot de passe du compte',
    change_modal_description: "Vous utiliserez votre nom d'utilisateur actuel et votre nouveau mot de passe pour vous connecter à la console d'administration.",
    new_password: 'Nouveau mot de passe',
    new_password_placeholder: 'Entrez votre mot de passe',
    confirm_password: 'Confirmez votre mot de passe',
    confirm_password_placeholder: 'Confirmez votre mot de passe',
    password_changed: 'Mot de passe changé !',
};
exports.default = settings;
