"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_resources = {
    title: 'API 资源',
    subtitle: '定义可以从已授权的应用程序中使用的 API',
    create: '创建 API 资源',
    api_name: 'API 名称',
    api_name_placeholder: '输入API名称',
    api_identifier: 'API Identifier',
    api_identifier_placeholder: 'https://your-api-identifier/',
    api_identifier_tip: '对于 API 资源的唯一标识符。它必须是一个绝对 URI 并没有 fragment (#) 组件。等价于 OAuth 2.0 中的 resource parameter。',
    api_resource_created: ' API 资源 {{name}} 已成功创建！',
};
exports.default = api_resources;
