"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const general = {
    placeholder: 'Placeholder',
    skip: 'Geç',
    next: 'Sonraki',
    retry: 'Tekrar Deneyin',
    done: 'Bitti',
    search: 'Ara',
    search_placeholder: 'Ara',
    clear_result: 'Sonuçları Temizle',
    save: 'Kaydet',
    save_changes: 'Değişiklikleri Kaydet',
    saved: 'Kaydedildi!',
    loading: 'Yükleniyor...',
    redirecting: 'Yönlendiriliyor...',
    add: 'Ekle',
    added: 'Eklendi',
    cancel: 'İptal Et',
    confirm: 'Onayla',
    check_out: 'Göz At',
    create: 'Oluştur',
    set_up: 'Kur',
    customize: 'Özelleştir',
    enable: 'Etkinleştir',
    reminder: 'Hatırlatıcı',
    delete: 'Sil',
    more_options: 'DAHA FAZLA SEÇENEK',
    close: 'Kapat',
    copy: 'Kopyala',
    copying: 'Kopyalanıyor',
    copied: 'Kopyalandı',
    required: 'Gerekli',
    add_another: '+ Bir tane daha ekle',
    deletion_confirmation: 'Bu dosyayı silmek istediğinize emin misiniz: {{title}}?',
    settings_nav: 'Ayarlar',
    unsaved_changes_warning: 'Bazı değişiklikler yaptınız. Bu sayfadan ayrılmak istediğine emin misin?',
    leave_page: 'Sayfayı terk et',
    stay_on_page: 'Bu sayfada kal',
    type_to_search: 'Type to search', // UNTRANSLATED
};
exports.default = general;
