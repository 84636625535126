"use strict";
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
Object.defineProperty(exports, "__esModule", { value: true });
exports.Users = void 0;
const zod_1 = require("zod");
const foundations_1 = require("../foundations");
const custom_types_1 = require("./custom-types");
const createGuard = zod_1.z.object({
    id: zod_1.z.string().max(12),
    username: zod_1.z.string().max(128).nullable().optional(),
    primaryEmail: zod_1.z.string().max(128).nullable().optional(),
    primaryPhone: zod_1.z.string().max(128).nullable().optional(),
    passwordEncrypted: zod_1.z.string().max(128).nullable().optional(),
    passwordEncryptionMethod: zod_1.z.nativeEnum(custom_types_1.UsersPasswordEncryptionMethod).nullable().optional(),
    name: zod_1.z.string().max(128).nullable().optional(),
    avatar: zod_1.z.string().max(2048).nullable().optional(),
    applicationId: zod_1.z.string().max(21).nullable().optional(),
    roleNames: foundations_1.roleNamesGuard.optional(),
    identities: foundations_1.identitiesGuard.optional(),
    customData: foundations_1.arbitraryObjectGuard.optional(),
    lastSignInAt: zod_1.z.number().nullable().optional(),
    createdAt: zod_1.z.number().optional(),
});
const guard = zod_1.z.object({
    id: zod_1.z.string().max(12),
    username: zod_1.z.string().max(128).nullable(),
    primaryEmail: zod_1.z.string().max(128).nullable(),
    primaryPhone: zod_1.z.string().max(128).nullable(),
    passwordEncrypted: zod_1.z.string().max(128).nullable(),
    passwordEncryptionMethod: zod_1.z.nativeEnum(custom_types_1.UsersPasswordEncryptionMethod).nullable(),
    name: zod_1.z.string().max(128).nullable(),
    avatar: zod_1.z.string().max(2048).nullable(),
    applicationId: zod_1.z.string().max(21).nullable(),
    roleNames: foundations_1.roleNamesGuard,
    identities: foundations_1.identitiesGuard,
    customData: foundations_1.arbitraryObjectGuard,
    lastSignInAt: zod_1.z.number().nullable(),
    createdAt: zod_1.z.number(),
});
exports.Users = Object.freeze({
    table: 'users',
    tableSingular: 'user',
    fields: {
        id: 'id',
        username: 'username',
        primaryEmail: 'primary_email',
        primaryPhone: 'primary_phone',
        passwordEncrypted: 'password_encrypted',
        passwordEncryptionMethod: 'password_encryption_method',
        name: 'name',
        avatar: 'avatar',
        applicationId: 'application_id',
        roleNames: 'role_names',
        identities: 'identities',
        customData: 'custom_data',
        lastSignInAt: 'last_sign_in_at',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'id',
        'username',
        'primaryEmail',
        'primaryPhone',
        'passwordEncrypted',
        'passwordEncryptionMethod',
        'name',
        'avatar',
        'applicationId',
        'roleNames',
        'identities',
        'customData',
        'lastSignInAt',
        'createdAt',
    ],
    createGuard,
    guard,
});
