"use strict";
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersPasswordEncryptionMethod = exports.SignInMode = exports.PasscodeType = exports.ApplicationType = void 0;
var ApplicationType;
(function (ApplicationType) {
    ApplicationType["Native"] = "Native";
    ApplicationType["SPA"] = "SPA";
    ApplicationType["Traditional"] = "Traditional";
    ApplicationType["MachineToMachine"] = "MachineToMachine";
})(ApplicationType = exports.ApplicationType || (exports.ApplicationType = {}));
var PasscodeType;
(function (PasscodeType) {
    PasscodeType["SignIn"] = "SignIn";
    PasscodeType["Register"] = "Register";
    PasscodeType["ForgotPassword"] = "ForgotPassword";
})(PasscodeType = exports.PasscodeType || (exports.PasscodeType = {}));
var SignInMode;
(function (SignInMode) {
    SignInMode["SignIn"] = "SignIn";
    SignInMode["Register"] = "Register";
    SignInMode["SignInAndRegister"] = "SignInAndRegister";
})(SignInMode = exports.SignInMode || (exports.SignInMode = {}));
var UsersPasswordEncryptionMethod;
(function (UsersPasswordEncryptionMethod) {
    UsersPasswordEncryptionMethod["Argon2i"] = "Argon2i";
})(UsersPasswordEncryptionMethod = exports.UsersPasswordEncryptionMethod || (exports.UsersPasswordEncryptionMethod = {}));
