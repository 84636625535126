"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const settings = {
    title: 'Ayarlar',
    description: 'Genel ayarları yönet',
    tabs: {
        general: 'Genel',
    },
    custom_domain: 'Özel alan',
    language: 'Dil',
    appearance: 'Görünüm',
    appearance_system: 'Sistemle senkronize et',
    appearance_light: 'Açık mod',
    appearance_dark: 'Koyu mod',
    saved: 'Kaydedildi!',
    change_password: 'Şifreyi Değiştir',
    change_password_description: 'Bu hesabın şifresini değiştirebilirsiniz. Yönetici Panelinde oturum açmak için mevcut kullanıcı adını yeni şifreyle kullanacaksınız.',
    change_modal_title: 'Hesap Şifresini Değiştir',
    change_modal_description: 'Yönetici Panelinde oturum açmak için mevcut kullanıcı adını yeni şifreyle kullanacaksınız.',
    new_password: 'Yeni şifre',
    new_password_placeholder: 'Şifre giriniz',
    confirm_password: 'Şifre onayla',
    confirm_password_placeholder: 'Şifrenizi onaylayınız',
    password_changed: 'Şifre değiştirildi!',
};
exports.default = settings;
