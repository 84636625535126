"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const contact = {
    title: 'Bizimle iletişime geçin',
    description: 'Geri bildirim sağlamak, yardım istemek ve düşüncelerinizi diğer geliştiricilerle paylaşmak için topluluğumuza katılın',
    discord: {
        title: 'Discord kanalı',
        description: 'Diğer geliştiricilerle sohbet etmek için herkese açık kanalımıza katılın',
        button: 'Katıl',
    },
    github: {
        title: 'GitHub',
        description: "Bir issue oluşturun ve GitHub'da gönderin",
        button: 'Aç',
    },
    email: {
        title: 'Eposta Gönder',
        description: 'Daha fazla bilgi ve yardım için bize bir e-posta gönderin',
        button: 'Gönder',
    },
};
exports.default = contact;
