@use '@/scss/underscore' as _;
@use '@/scss/dimensions' as dim;

.body {
  .connectorGroup {
    gap: _.unit(4);
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: dim.$modal-layout-grid-large) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: dim.$modal-layout-grid-medium) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: dim.$modal-layout-grid-small) {
      grid-template-columns: repeat(1, 1fr);
    }

    &.medium {
      grid-template-columns: repeat(2, 1fr);

      @media screen and (max-width: dim.$modal-layout-grid-small) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &.large {
      grid-template-columns: repeat(3, 1fr);

      @media screen and (max-width: dim.$modal-layout-grid-medium) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: dim.$modal-layout-grid-small) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .connector {
      font: var(--font-body-medium);
      display: flex;

      .logo {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        margin-right: _.unit(3);

        img {
          width: 40px;
          height: 40px;
        }
      }

      .content {
        flex: 1;

        .name {
          font: var(--font-subhead-2);
          @include _.multi-line-ellipsis(1);

          &.nameWithRightPadding {
            padding-right: _.unit(12); /* For check mark and added label */
          }
        }

        .connectorId {
          margin-top: _.unit(1);
          font: var(--font-body-small);
          color: var(--color-text-secondary);
        }

        .description {
          font: var(--font-body-small);
          color: var(--color-text-secondary);
          margin-top: _.unit(1);
          @include _.multi-line-ellipsis(4);
        }
      }
    }
  }
}
