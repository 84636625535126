@use '@/scss/underscore' as _;

.container {
  display: flex;
  align-items: center;
  padding: _.unit(2);
  border-radius: 8px;
  transition: background-color 0.2s ease-in-out;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: var(--color-hover-variant);
  }

  &.active {
    background-color: var(--color-focused-variant);
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  span {
    font: var(--font-subhead-2);
    color: var(--color-text);
  }
}

.dropdownTitle {
  @include _.subhead-cap;
  padding: _.unit(5) _.unit(5) _.unit(3) _.unit(4);
}

.dropdown {
  padding: 0;
}

.index {
  width: 20px;
  height: 20px;
  font: var(--font-subhead-cap);

  > svg {
    width: 16px;
    height: 16px;
  }
}

.dropdownItem {
  height: 42px;
  padding: 0 _.unit(5) 0 _.unit(4);
  border-top: 1px solid var(--color-hover);
  border-bottom: 1px solid transparent;
  border-radius: unset;

  &:last-child {
    // Note: use 7px to avoid display issue with parent border-radius
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  &:hover:not(:last-child) {
    border-bottom-color: var(--color-hover);

    + .dropdownItem {
      border-top-color: transparent;
    }
  }
}
