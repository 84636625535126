"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const connector_details = {
    back_to_connectors: 'Connectorlara dön',
    check_readme: 'READMEye göz at',
    save_error_empty_config: 'Lütfen yapılandırmayı girin',
    send: 'Gönder',
    send_error_invalid_format: 'Geçersiz input',
    edit_config_label: 'json girin ',
    test_email_sender: 'eposta connectorunuzu test edin',
    test_sms_sender: 'SMS connectorunuzu test edin',
    test_email_placeholder: 'Test e-posta adresi girin',
    test_sms_placeholder: 'Test telefon numarası girin',
    test_message_sent: 'Test mesajı gönderildi!',
    test_sender_description: 'Eğer jsonunuz doğru yapılandırılmışsa bir mesaj alacaksınız',
    options_change_email: 'Eposta connectorunuzu değiştirin',
    options_change_sms: 'SMS connectorunuzu değiştirin',
    connector_deleted: 'Connector başarıyla silindi',
    type_email: 'Eposta connectorı',
    type_sms: 'SMS connectorı',
    type_social: 'Social connector',
};
exports.default = connector_details;
