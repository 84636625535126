"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const connector_details = {
    back_to_connectors: 'Retour à Connecteurs',
    check_readme: 'Vérifier le README',
    save_error_empty_config: 'Veuillez entrer la configuration',
    send: 'Envoyer',
    send_error_invalid_format: 'Entrée non valide',
    edit_config_label: 'Entrez votre json ici',
    test_email_sender: 'Testez votre connecteur Email',
    test_sms_sender: 'Testez votre connecteur SMS',
    test_email_placeholder: 'Entrez une adresse email de test',
    test_sms_placeholder: 'Entrez un numéro de téléphone de test',
    test_message_sent: 'Message de test envoyé !',
    test_sender_description: 'Vous recevrez un message si votre json est correctement configuré.',
    options_change_email: 'Modifier le connecteur Email',
    options_change_sms: 'Changer le connecteur SMS',
    connector_deleted: 'Le connecteur a été supprimé avec succès',
    type_email: 'Connecteur Email',
    type_sms: 'Connecteur SMS',
    type_social: 'Connecteur Social',
};
exports.default = connector_details;
